import { AnyAction, combineReducers } from 'redux';

import { plug, sessionExpired, islogin, statusesToast } from '@redux/slices';
import { globalReducer, projectReducer, selfData, userReducer } from '@api/@slices';
import { backgroundReducer } from 'components/@common/Background/@slices';
import { workspaceProductsPDFReducer } from 'components/@common/WorkspaceProductsPDF/@slices';
import { shortlistProductsPDFReducer } from 'components/@common/ShortlistProductsPDF/@slices';
import { requestDemoReducer } from 'components/@main/RequestDemo/@slices';
import { timeLineBarReducer } from 'components/@main/TimeLineBar/@slices';
import { authReducer } from 'components/@common/Auth/@slices';
import { recoveryPasswordReducer } from 'pages/Auth/@slices';
import { scorecardReducer } from 'pages/Scorecard/@slices';
import { vendorsReducer } from 'pages/Vendors/@slices';
import { scoresReducer } from 'pages/Summary/@slices';
import { workspaceReducer } from 'pages/Workspace/@slices';
import { teamReducer } from 'pages/Team/@slices';
import { invitationReducer } from 'pages/Invitations/@slices';
import { comingSoonReducer } from 'components/@common/ComingSoonState/@slices';
import { settingsReducer } from 'pages/Settings/@slices';
import { vendorReducer } from 'pages/Vendor/@slices';

const appReducer = combineReducers({
  selfData: selfData.reducer,
  user: userReducer,
  plug: plug.reducer,
  background: backgroundReducer,
  sessionExpired: sessionExpired.reducer,
  islogin: islogin.reducer,
  statusesToast: statusesToast.reducer,
  project: projectReducer,
  global: combineReducers({
    ...globalReducer,
    workspaceProductsPDFReducer: workspaceProductsPDFReducer,
    shortlistProductsPDFReducer: shortlistProductsPDFReducer,
  }),
  auth: combineReducers({
    ...authReducer,
    ...recoveryPasswordReducer,
  }),
  scorecard: scorecardReducer,
  vendors: vendorsReducer,
  timeLineBar: timeLineBarReducer,
  scores: scoresReducer,
  workspace: workspaceReducer,
  team: teamReducer,
  invitation: invitationReducer,
  comingSoon: comingSoonReducer,
  settings: settingsReducer,
  vendor: vendorReducer,
  requestDemo: requestDemoReducer,
});

export const LOG_OUT_TYPE = 'LOG_OUT';

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: AnyAction) => {
  /* if you are using RTK, you can import your action and use it's type property instead of the literal definition of the action  */
  if (action.type === LOG_OUT_TYPE) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
