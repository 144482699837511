import { timeLinePermissions } from 'components/@main/TimeLineBar/permissions';
import { vendorsPermissions } from 'pages/Vendors/permissions';
import { worspacePermissions } from 'pages/Workspace/permissions';
import { teamPermissions } from 'pages/Team/permissions';
import { summaryPermissions } from 'pages/Summary/permissions';
import { scorecardPermissions } from 'pages/Scorecard/permissions';
import { settingsPermissions } from 'pages/Settings/@permissions';

const permissions = {
  ...timeLinePermissions,
  ...vendorsPermissions,
  ...worspacePermissions,
  ...teamPermissions,
  ...summaryPermissions,
  ...scorecardPermissions,
  ...settingsPermissions,
} as const;

export default permissions;

export type PermissionsType = keyof typeof permissions;
export type PermissionsProp = PermissionsType | PermissionsType[];
export type PermissionsArrayMethod = 'all' | 'any';

export type PermissionsRules = Partial<Record<PermissionsType, boolean>>;
