import React from 'react';

import Box from 'components/@ui/Box';
import Typography from 'components/@ui/Typography';
import TypographyMobile from 'components/@ui/TypographyMobile';
import Image from 'components/@ui/Image';
import ScheduleOnboardingButton from './ScheduleOnboardingButton';
import { useIsTablet } from '@hooks';

const MainInformationHeader: React.FC = () => {
  const isTablet = useIsTablet();

  return (
    <Box
      display="flex"
      flexDirection={isTablet ? 'column-reverse' : 'row'}
      alignItems={isTablet ? 'center' : 'start'}
    >
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Typography semiBold fontSize={24} lineHeight="32px">
          Unlock the #1 HR Tech Platform for Corporate Teams
        </Typography>
        <TypographyMobile mobileRegularFontSize={isTablet} mt={3}>
          Schedule an onboarding meeting to access OutSail's{' '}
          <Typography component="span" semiBold>
            free
          </Typography>{' '}
          tools & tailored support
        </TypographyMobile>
        <ScheduleOnboardingButton sx={{ mt: 1.5, width: isTablet ? 1 : 'auto' }} />
      </Box>
      <Box p={2}>
        <Image src={require('assets/time-to-go.png')} width={268} height={268} />
      </Box>
    </Box>
  );
};

export default MainInformationHeader;

